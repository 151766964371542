import axios from 'axios';
import store from '@/store';
import app from '@/main';

let clientAPI = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

// Add Authorization
clientAPI.interceptors.request.use((config) => {
    config.headers.page = document.location.pathname;
    if (store) {
        config.headers.Authorization = store.getters.token;
        store.dispatch('beforeCallAPI');
    }
    return config;
});

// Add 401 interceptor
clientAPI.interceptors.response.use(
    function (response) {
        store.dispatch('afterCallAPI');
        return response;
    },
    function (error) {
        if (401 === error.response.status) {
            store.dispatch('showError', app.$t('login.logout'));
            store.dispatch('logoutToApp', error.response.config.headers.page);
        } else {
            return Promise.reject(error);
        }
    },
);

export const API = clientAPI;
