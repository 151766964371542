<template>
    <v-app>
        <v-system-bar v-if="isDevelopment" dark color="red" app> DEV</v-system-bar>
        <app-messages />
        <template v-if="token">
            <router-view class="d-print-none" name="drawer"></router-view>
        </template>
        <app-bar v-if="token" />
        <v-main :style="`background-color: ${backColor}`">
            <v-container :class="containerClazz" fluid>
                <router-view :key="$route.fullPath"></router-view>
            </v-container>
        </v-main>

        <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
            {{ $t('app.new') }}
            <template v-slot:action="{ attrs }">
                <v-btn text @click="refreshApp" v-bind="attrs">
                    {{ $t('buttons.refresh') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import appMessages from '@/components/messages.vue';
import appBar from '@/components/toolbar.vue';
import update from './mixins/update';

export default {
    name: 'App',
    components: {},
    data: () => ({
        visible: false,
    }),
    mixins: [update],
    created() {
        this.loadProfile();
        this.updateNotifications();
        document.addEventListener('visibilitychange', this.updateNotifications);
    },
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.updateNotifications);
    },
    components: { appMessages, appBar },
    beforeMount() {
        const theme = localStorage.getItem('product_theme');
        if (theme && theme === 'dark') {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }
    },
    watch: {
        profile: function () {
            const theme = localStorage.getItem('product_theme');
            if (theme && theme === 'dark') {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        },
    },
    computed: {
        ...mapGetters(['token', 'profile']),
        containerClazz() {
            if (!this.token) {
                return 'fill-height container-full';
            }

            return '';
        },
        backColor() {
            return this.$vuetify.theme.dark ? '#424242' : '#F3F2EF';
        },
        isDevelopment() {
            return process.env.NODE_ENV === 'development';
        },
    },
    methods: {
        ...mapActions(['findMyNotifications', 'loadProfile']),
        updateNotifications() {
            if (this.$store && this.$store.getters.token) {
                if (document.visibilityState === 'visible' && !this.visible) {
                    this.findMyNotifications();
                }

                this.visible = document.visibilityState === 'visible';
            }
        },
    },
};
</script>

<style>
.container-full {
    background-color: #424242;
}
.v-application code {
    background-color: #282b2e !important;
    color: #a9b7c6 !important;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
}

.v-alert__content {
    max-width: 100%;
    overflow-wrap: anywhere;
}

.v-menu__content .theme--dark.v-card {
    background-color: #424242;
}

.v-menu__content .v-card {
    width: 450px;
}
</style>
