const state = {
    message: undefined,
    display: false,
    type: 'info',
};

const getters = {
    snackMessage: (state) => {
        return state.message;
    },
    snackDisplay: (state) => {
        return state.display;
    },
    snackType: (state) => {
        return state.type;
    },
};

const mutations = {
    DISPLAY_MESSAGE(state, context) {
        state.message = context.message;
        state.type = context.type;
        state.display = true;
    },
    HIDE_MESSAGE(state) {
        state.display = false;
        state.message = undefined;
    },
};

const actions = {
    showSuccess({ commit }, message) {
        const context = {
            type: 'success',
            message: message,
        };
        commit('DISPLAY_MESSAGE', context);
    },
    showInfo({ commit }, message) {
        const context = {
            type: 'info',
            message: message,
        };
        commit('DISPLAY_MESSAGE', context);
    },
    showWarning({ commit }, message) {
        const context = {
            type: 'warning',
            message: message,
        };
        commit('DISPLAY_MESSAGE', context);
    },
    showError({ commit }, message) {
        const context = {
            type: 'error',
            message: message,
        };
        commit('DISPLAY_MESSAGE', context);
    },
    hideMessage({ commit }) {
        commit('HIDE_MESSAGE');
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
