import app from '@/main';
import { API } from '@/utils/api';

const state = {
    issue: undefined,
};

const getters = {
    issue: (state) => {
        return state.issue;
    },
    issueAttributes: (state) => {
        return [
            'name',
            'description',
            'tracker',
            'status',
            'priority',
            'severity',
            'module',
            'category',
            'versionImpact',
            'versionWanted',
            'versionTarget',
            'done',
            'time',
            'startedAt',
            'endedAt',
            'assignedTo',
            'releaseNote',
            'releaseNoteDetail',
            'supportClient',
            'supportInput',
            'supportOutput',
            'supportDetail',
            'parent',
        ];
    },
    timeActivity: (state) => {
        return ['conception', 'development', 'test', 'correction', 'installation', 'intervention', 'documentation'];
    },
    commentTags: (state) => {
        return ['close', 'relaunch'];
    },
};

const mutations = {
    UPDATE_ISSUE(state, issue) {
        state.issue = issue;
    },
};

const actions = {
    findIssue({ commit }, id) {
        commit('UPDATE_ISSUE', undefined);
        API.get('/issues/' + id)
            .then((response) => {
                if (response.data.success) {
                    commit('UPDATE_ISSUE', response.data.datas);
                }
            })
            .catch(() => {
                app.$router.push({ path: '/not-found' });
            });
    },
    searchIssues({}, search) {
        const query = {
            cols: ['name', 'number'],
            filters: [
                { field: 'number', operator: '$eq', value: search.query },
                { field: 'project', operator: '$eq', value: search.project },
            ],
        };
        if (search.issue) {
            query.filters.push({ field: '_id', operator: '$ne', value: search.issue });
        }
        return API.post('/issues/search', query);
    },
    findIssueLabel({}, search) {
        const query = {
            cols: ['name', 'number'],
            filters: [{ field: '_id', operator: '$eq', value: search }],
        };
        return API.post('/issues/search', query);
    },
    createIssue({ commit, dispatch }, issue) {
        return API.put('/issues/', issue).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showSuccess', app.$t('issues.actions.created_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    updateIssue({ commit, dispatch, getters }, issue) {
        return API.post('/issues/' + issue.id, issue).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addWatcherToIssue({ commit, dispatch }, issue) {
        return API.post('/issues/' + issue.id + '/watchers', issue).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    deleteWatcherToIssue({ commit, dispatch }, issue) {
        return API.request({
            method: 'delete',
            url: `/issues/${issue.id}/watchers`,
            data: issue,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addInterestedToIssue({ commit, dispatch }, issue) {
        return API.post('/issues/' + issue.id + '/interesteds', issue).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    deleteInterestedToIssue({ commit, dispatch }, issue) {
        return API.request({
            method: 'delete',
            url: `/issues/${issue.id}/interesteds`,
            data: issue,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addFilesToIssue({ commit, dispatch }, issue) {
        return API.post('/issues/' + issue.id + '/files', issue.data).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    deleteFileToIssue({ commit, dispatch }, issue) {
        return API.delete('/issues/' + issue.id + '/files/' + issue.file).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addFilesToNewIssue({ dispatch }, files) {
        return API.post('/files/', files.data).then((response) => {
            if (response.data.success) {
                dispatch('showInfo', app.$t('issues.actions.added_files_success'));
                return Promise.resolve(response.data.datas);
            } else {
                return Promise.reject();
            }
        });
    },
    deleteFileToNewIssue({ commit, dispatch }, file) {
        // Remove /api from url
        return API.delete(file.url.substring(4)).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.deleted_files_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addCommentToIssue({ commit, dispatch }, issue) {
        return API.put('/issues/' + issue.id + '/comments', issue.comment).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    updateCommentToIssue({ commit, dispatch }, issue) {
        return API.post('/issues/' + issue.id + '/comments', issue.comment).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addTrackingToIssue({ commit, dispatch }, issue) {
        return API.put('/issues/' + issue.id + '/tracking', issue.tracking).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    updateTrackingToIssue({ commit, dispatch }, issue) {
        return API.post('/issues/' + issue.id + '/tracking', issue.tracking).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addTaskToIssue({ commit, dispatch }, issue) {
        return API.put('/issues/' + issue.id + '/tasks', issue.task).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    updateTaskToIssue({ commit, dispatch }, issue) {
        return API.post('/issues/' + issue.id + '/tasks', issue.task).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    deleteTaskToIssue({ commit, dispatch }, issue) {
        return API.delete('/issues/' + issue.id + '/tasks/' + issue.taskId).then((response) => {
            if (response.data.success) {
                commit('UPDATE_ISSUE', response.data.datas);
                dispatch('showInfo', app.$t('issues.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
