import app from '@/main';
import { API } from '@/utils/api';

const state = {
    packs: [],
    pack: undefined,
};

const getters = {
    packs: (state) => {
        return state.packs;
    },
    pack: (state) => {
        return state.pack;
    },
};

const mutations = {
    UPDATE_PACKS(state, packs) {
        state.packs = packs;
    },
    UPDATE_PACK(state, pack) {
        if (pack) {
            state.packs = state.packs.filter((p) => p.id !== pack.id).concat(pack);
            state.pack = pack;
        } else {
            state.pack = undefined;
        }
    },
};

const actions = {
    findPacks({ commit }) {
        API.get('/packs').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', undefined);
                commit('UPDATE_PACKS', response.data.datas);
            }
        });
    },
    findAllPacks({ commit }) {
        API.get('/packs').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', undefined);
                commit('UPDATE_PACKS', response.data.datas);
            }
        });
    },
    findPack({ commit, dispatch }, id) {
        commit('UPDATE_PACK', undefined);
        API.get('/packs/' + id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('findUserLabels', response.data.datas.members);
            }
        });
    },
    createPack({ commit, dispatch }, pack) {
        API.put('/packs', pack).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.created_success'));
            }
        });
    },
    updatePack({ commit, dispatch }, pack) {
        API.post('/packs/' + pack.id, pack).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
    generatePackVersion({ commit, dispatch }, packVersion) {
        API.get('/packs/' + packVersion.id + '/versions/' + packVersion.version + '/generate').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
    addVersionToPack({ commit, dispatch }, version) {
        API.put('/packs/' + version.pack_id + '/versions/', version).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
    updatePackVersion({ commit, dispatch }, version) {
        API.post('/packs/' + version.pack_id + '/versions/' + version.id, version).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
    deleteVersionToPack({ commit, dispatch }, version) {
        API.request({
            method: 'delete',
            url: `/packs/${version.pack_id}/versions/${version.id}`,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
    addProjectToPackVersion({ commit, dispatch }, project) {
        API.put('/packs/' + project.pack_id + '/versions/' + project.version_id + '/projects', project).then(
            (response) => {
                if (response.data.success) {
                    commit('UPDATE_PACK', response.data.datas);
                    dispatch('showInfo', app.$t('packs.actions.updated_success'));
                }
            },
        );
    },
    updateProjectToPackVersion({ commit, dispatch }, project) {
        API.put(
            '/packs/' + project.pack_id + '/versions/' + project.version_id + '/projects/' + project.id,
            project,
        ).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
    removeProjectToPackVersion({ commit, dispatch }, project) {
        API.request({
            method: 'delete',
            url: `/packs/${project.pack_id}/versions/${project.version_id}/projects/${project.id}`,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PACK', response.data.datas);
                dispatch('showInfo', app.$t('packs.actions.updated_success'));
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
