import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import { VAlert } from 'vuetify/lib';

Vue.use(Vuetify, {
    components: {
        VAlert,
    },
});

let options = {
    lang: {
        locales: { fr },
        current: 'fr',
    },
};

export default new Vuetify(options);
