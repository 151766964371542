import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/head';
import './plugins/veevalidate';
import './plugins/shortkey';

Vue.config.productionTip = false;

export const bus = new Vue();
export const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

export default app;
