<template>
    <v-autocomplete
        :autofocus="autofocus"
        v-model="localProject"
        :items="projectsItems"
        :outlined="outlined"
        :label="label"
        :readonly="readonly"
        v-on:input="selectProject"
    ></v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SortBy from 'lodash/sortBy';
export default {
    name: 'selector-project',
    data: () => ({
        localProject: undefined,
    }),
    created() {
        if (this.projects.length === 0) {
            this.findAllProjects();
        }
    },
    mounted() {
        this.localProject = this.value;
    },
    props: {
        value: {},
        label: {
            default: '',
        },
        outlined: {
            default: false,
        },
        ignored: {
            default: () => {
                return [];
            },
        },
        readonly: {
            default: false,
        },
        type: {
            default: 'name',
        },
        displayPath: {
            default: true,
        },
        autofocus: {
            default: false,
        },
    },
    watch: {
        localProject: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localProject = value;
        },
    },
    computed: {
        ...mapGetters(['projects']),
        projectsToDisplay() {
            return this.projects.filter((p) => !this.ignored.includes(this.type === 'id' ? p.id : p.name));
        },
        projectsItems() {
            return SortBy(
                this.projectsToDisplay.map((p) => {
                    return {
                        text: this.displayPath ? p.group + ' / ' + p.label : p.label,
                        value: this.type === 'id' ? p.id : p.name,
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
    },
    methods: {
        ...mapActions(['findAllProjects']),
        selectProject(item) {
            this.$emit('update-value', item);
            this.$emit(
                'update-project',
                this.projectsToDisplay.find((p) => {
                    const filter = this.type === 'id' ? p.id : p.name;

                    return filter === item;
                }),
            );
        },
    },
};
</script>
