const state = {
    loading: false,
};

const getters = {
    loadingAPI: (state) => {
        return state.loading;
    },
};

const mutations = {
    LOADING_API(state, loading) {
        state.loading = loading;
    },
};

const actions = {
    beforeCallAPI({ commit }) {
        commit('LOADING_API', true);
    },
    afterCallAPI({ commit }) {
        commit('LOADING_API', false);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
