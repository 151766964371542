<template>
    <!-- App Bar -->
    <v-app-bar app elevate-on-scroll dense clipped-left :color="toolbarColor" v-if="profile" class="d-print-none">
        <v-dialog v-model="lightbox" width="500">
            <v-card>
                <v-card-text v-if="type === 'search'">
                    <v-text-field
                        autofocus
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        single-line
                        :label="$t('issues.global_search')"
                        v-model="referenceToGo"
                        v-on:keyup.enter.prevent="gotoIssue()"
                    ></v-text-field>
                </v-card-text>
                <v-card-text v-if="type === 'product'">
                    <project-selector
                        v-model="productToGo"
                        :autofocus="true"
                        v-on:update-value="gotoProject"
                        type="id"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <router-link :to="{ name: 'projects' }">
            <v-img
                v-if="$vuetify.theme.dark"
                class="shrink"
                contain
                src="../assets/Logo_blanc.png"
                transition="scale-transition"
            />
            <v-img v-else class="shrink" contain src="../assets/Logo_noir.png" transition="scale-transition" />
        </router-link>
        <v-btn class="ml-4" v-if="isProjectView" icon small @click="updateFavoriteStatus()">
            <v-icon v-if="isFavorite" color="grey darken-2">mdi-star</v-icon>
            <v-icon v-else>mdi-star-outline</v-icon>
        </v-btn>
        <router-link
            :to="project ? { name: 'project', params: { project_id: project.id } } : { name: 'projects' }"
            class="d-none d-sm-flex align-center text--primary"
            style="text-decoration: none"
        >
            <v-toolbar-title class="mx-2" v-text="title"></v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>

        <v-toolbar-items>
            <v-btn :to="{ name: 'dashboard' }" text>{{ $t('menu.dashboard') }}</v-btn>
            <v-btn v-if="profile.security.packsAccess || profile.security.packsCreate" :to="{ name: 'packs' }" text>{{
                $t('menu.packs')
            }}</v-btn>
            <v-btn v-if="profile.security.projects" :to="{ name: 'projects' }" text>{{ $t('menu.projects') }}</v-btn>
            <v-btn
                v-if="profile.security.sprints"
                :to="{
                    name: teams.length === 1 ? 'team' : 'teams',
                    params: { team_id: teams.length === 1 ? teams[0].id : '' },
                }"
                text
                >{{ $t('menu.teams') }}</v-btn
            >
            <v-btn v-if="profile.security.timesAccess" :to="{ name: 'times' }" text>{{ $t('menu.times') }}</v-btn>
            <v-btn :to="{ name: 'calendar' }" text>{{ $t('menu.calendar') }}</v-btn>
            <v-btn v-if="profile.security.knowledgeAccess" :to="{ name: 'knowledges' }" text>
                {{ $t('menu.knowledges') }}
            </v-btn>
            <v-btn v-if="profile.security.analyticsAccess" :to="{ name: 'analytics' }" text>
                {{ $t('menu.analytics') }}
            </v-btn>
            <v-btn
                icon
                v-shortkey="['ctrl', 'i']"
                @shortkey.native="openLightBox('search')"
                @click="openLightBox('search')"
                color="grey darken-1"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
                icon
                v-shortkey="['ctrl', 'p']"
                @shortkey.native="openLightBox('product')"
                @click="openLightBox('product')"
                color="grey darken-1"
            >
                <v-icon>mdi-bookmark-multiple-outline</v-icon>
            </v-btn>

            <v-btn icon v-if="notifications.length === 0" color="grey darken-1">
                <v-icon>mdi-bell-outline</v-icon>
            </v-btn>

            <v-menu v-model="notificationMenu" offset-y :close-on-content-click="false" v-if="notifications.length > 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-on="on" color="grey darken-1">
                        <v-badge color="red" dot v-bind="attrs" :value="notifications.length > 0">
                            <v-icon v-if="notifications.length > 0">mdi-bell-ring-outline</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="issueLink text-center">
                            <v-btn text @click="readAllNotifications">
                                <v-icon color="grey lighten-1">mdi-bell-check-outline</v-icon>
                                {{ $t('menu.notificationsRead') }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item two-line v-for="(notification, index) in notifications" :key="index">
                        <v-list-item-content
                            @click="gotoIssue(notification.object, notification.id)"
                            class="text--darken-2"
                            :class="notification.parameters.type === 'create' ? 'green--text' : 'blue--text'"
                        >
                            <v-list-item-title class="issueLink">
                                #{{ notification.parameters.number }}
                                {{ notification.parameters.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="issueLink">
                                <template v-if="notification.parameters.type === 'create'">
                                    {{ $t('issues.actions.added_by') }} {{ notification.parameters.updatedBy }}
                                </template>
                                <template v-if="notification.parameters.type === 'update'">
                                    {{ $t('issues.actions.updated_by') }} {{ notification.parameters.updatedBy }}
                                </template>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon @click="readNotification(notification.id)">
                                <v-icon color="grey lighten-1">mdi-bell-check-outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn v-if="profile.security.administration" icon :to="{ name: 'settings' }" color="grey darken-1">
                <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
        </v-toolbar-items>

        <v-menu left bottom :close-on-content-click="true">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs">
                    <v-avatar size="36px" item>
                        <v-img :src="profile.avatar" />
                    </v-avatar>
                </v-btn>
            </template>
            <v-card tile class="mx-auto">
                <v-card-subtitle v-text="profile.fullname"></v-card-subtitle>
                <v-divider />
                <v-list dense>
                    <v-list-item-group color="primary">
                        <v-list-item :to="{ name: 'profile' }">
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="$t('users.menu.profile')"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="logoutToApp()">
                            <v-list-item-icon>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="$t('users.menu.logout')"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-menu>

        <v-progress-linear
            :active="loadingAPI"
            :indeterminate="loadingAPI"
            absolute
            bottom
            color="blue-grey accent-4"
        ></v-progress-linear>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import projectSelector from './selectors/simple/project';

export default {
    name: 'toolbar',
    data: () => ({
        referenceToGo: undefined,
        productToGo: undefined,
        lightbox: false,
        type: 'search',
        notificationMenu: false,
    }),
    watch: {
        '$vuetify.theme.dark': function (value) {
            localStorage.setItem('product_theme', value ? 'dark' : 'light');
        },
    },
    components: { projectSelector },
    updated() {
        this.productToGo = undefined;
    },
    computed: {
        ...mapGetters(['profile', 'loadingAPI', 'project', 'teams', 'team', 'notifications', 'dashboardViews']),
        title() {
            let title = 'IPO Product';

            if (this.isProjectView) {
                title = this.project.label;
            }
            if (this.isTeamView) {
                title = this.team.name;
            }

            return title;
        },
        isProjectView() {
            return this.$route.name && this.$route.name.startsWith('project-') && this.project;
        },
        isTeamView() {
            return this.$route.name && this.$route.name.startsWith('team-') && this.team;
        },
        isFavorite() {
            if (this.project) {
                return this.project.favorites.includes(this.profile.name);
            }

            return false;
        },
        toolbarColor() {
            return this.$vuetify.theme.dark ? '' : 'white';
        },
    },
    methods: {
        ...mapActions(['logoutToApp', 'addProjectToFavorite', 'removeProjectToFavorite', 'readNotification']),
        openLightBox(type) {
            this.lightbox = true;
            this.type = type;
        },
        updateFavoriteStatus() {
            if (this.isFavorite) {
                this.removeProjectToFavorite(this.project.id);
            } else {
                this.addProjectToFavorite(this.project.id);
            }
        },
        gotoIssue(id, notificationId) {
            if (notificationId) {
                this.readNotification(notificationId);
                this.notificationMenu = false;
            }
            if (id && id.startsWith('issue://')) {
                const path = id.split('/');
                id = path[path.length - 1];
            }
            this.lightbox = false;
            this.$router.push({
                name: 'issue-redirect',
                params: { issue_id: id ? id : this.referenceToGo },
            });
            this.referenceToGo = undefined;
        },
        gotoProject(id) {
            this.lightbox = false;
            this.$router.push({
                name: 'project-issues',
                params: { project_id: id },
            });
            this.productToGo = undefined;
        },
        readAllNotifications() {
            this.notifications.forEach((n) => {
                this.readNotification(n.id);
            });
            this.notificationMenu = false;
        },
    },
};
</script>

<style scoped>
.issueLink {
    cursor: pointer;
}
</style>
