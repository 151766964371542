<template>
    <v-snackbar :value="snackDisplay" v-on:input="hideMessage" :timeout="4000" :color="snackType">
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
            <v-btn text @click="hideMessage" v-bind="attrs">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'messages',
    data: () => ({}),
    computed: {
        ...mapGetters(['snackDisplay', 'snackMessage', 'snackType']),
    },
    methods: {
        ...mapActions(['hideMessage']),
    },
};
</script>
