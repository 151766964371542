import app from '@/main';
import { API } from '@/utils/api';

const LOCALSTORAGE_PROFILE = 'product_profile';
const SESSIONSTORAGE_REDIRECT = 'product_redirect';

const state = {
    profile: undefined,
    token: undefined,
};

const getters = {
    token: (state) => {
        return state.token ? state.token : undefined;
    },
    profile: (state) => {
        return state.profile;
    },
};

const mutations = {
    UPDATE_TOKEN(state, token) {
        state.token = token;
    },
    UPDATE_PROFILE(state, profile) {
        state.profile = profile;
        state.token = profile ? profile.token : undefined;
        if (profile) {
            localStorage.setItem('product_theme', profile.theme);
        }
    },
};

const actions = {
    loadProfile({ commit, dispatch }) {
        const profile = window.localStorage.getItem(LOCALSTORAGE_PROFILE);
        const profileJSON = JSON.parse(profile);
        commit('UPDATE_PROFILE', profileJSON);
        if (profile) {
            dispatch('loadAppConfig');
        }
    },
    loginToAppDev({ commit, dispatch }, payload) {
        dispatch('saveRedirectPage', payload._to);

        API.post('/auth', {
            email: payload.email,
            fullname: payload.fullname,
        }).then((response) => {
            if (response.data.success) {
                dispatch('loadAppConfig');
                commit('UPDATE_PROFILE', response.data.datas);
                window.localStorage.setItem(LOCALSTORAGE_PROFILE, JSON.stringify(response.data.datas));

                dispatch('redirectToPage', response.data.datas);
            }
        });
    },
    loginToApp({ dispatch }, payload) {
        dispatch('saveRedirectPage', payload._to);

        window.location = '/api/auth/visiativ';
    },
    logoutToApp({ commit }, redirect) {
        commit('UPDATE_PROFILE', undefined);
        window.localStorage.removeItem(LOCALSTORAGE_PROFILE);
        app.$router.push({
            name: 'login',
            params: { nextUrl: redirect ? redirect : undefined },
        });
    },
    findMyProfile({ commit, dispatch }) {
        API.get(`/users/profile`).then((response) => {
            if (response.data.success) {
                dispatch('loadAppConfig');
                commit('UPDATE_PROFILE', response.data.datas);
                window.localStorage.setItem(LOCALSTORAGE_PROFILE, JSON.stringify(response.data.datas));

                dispatch('redirectToPage', response.data.datas);
            }
        });
    },
    updateMyProfile({ commit, dispatch }, profile) {
        API.post(`/users/${profile.id}`, profile).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROFILE', response.data.datas);
                window.localStorage.setItem(LOCALSTORAGE_PROFILE, JSON.stringify(response.data.datas));
                dispatch('showInfo', app.$t('users.actions.updated_success'));
            }
        });
    },
    addSubscription({ commit, dispatch }, profile) {
        API.post(`/users/${profile.id}/subscriptions`, profile.subscription).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROFILE', response.data.datas);
                window.localStorage.setItem(LOCALSTORAGE_PROFILE, JSON.stringify(response.data.datas));
                dispatch('showInfo', app.$t('users.actions.updated_success'));
            }
        });
    },
    saveRedirectPage({}, page) {
        window.sessionStorage.setItem(SESSIONSTORAGE_REDIRECT, page);
    },
    redirectToPage({}, user) {
        const profileRedirection = user ? '/' + user.defaultPage : '/dashboard';

        const redirect = window.sessionStorage.getItem(SESSIONSTORAGE_REDIRECT);
        window.sessionStorage.removeItem(SESSIONSTORAGE_REDIRECT);
        app.$router.push(redirect ? redirect : profileRedirection);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
