import { API } from '@/utils/api';

const state = {
    views: [],
};

const getters = {
    dashboardViews: (state) => {
        return state.views;
    },
};

const mutations = {
    UPDATE_DASHBOARD_VIEWS(state, views) {
        state.views = views;
    },
};

const actions = {
    findViewsFromProfiles({ commit }) {
        return API.get('/profiles/views').then((response) => {
            if (response.data.success) {
                commit('UPDATE_DASHBOARD_VIEWS', response.data.datas);
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
