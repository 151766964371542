import { API } from '@/utils/api';

const state = {
    notifications: [],
};

const getters = {
    notifications: (state) => {
        return state.notifications;
    },
};

const mutations = {
    ADD_NOTIFICATIONS(state, notifications) {
        const idToRemove = notifications.map((n) => n.id);
        state.notifications = state.notifications.filter((i) => !idToRemove.includes(i.id)).concat(notifications);
    },
    REMOVE_NOTIFICATION(state, notification) {
        state.notifications = state.notifications.filter((n) => n.id !== notification);
    },
};

const actions = {
    findMyNotifications({ state, commit }) {
        API.post('/notifications', { type: 'issues' }).then((response) => {
            if (response.data.success) {
                commit('ADD_NOTIFICATIONS', response.data.datas);
            }
        });
    },
    readNotification({ commit }, id) {
        API.get('/notifications/' + id + '/ack').then((response) => {
            if (response.data.success) {
                commit('REMOVE_NOTIFICATION', id);
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
