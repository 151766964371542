import app from '@/main';
import { API } from '@/utils/api';

const state = {
    knowledges: [],
    knowledge: undefined,
    total: 10,
};

const getters = {
    knowledges: (state) => {
        return state.knowledges;
    },
    totalKnowledges: (state) => {
        return state.total;
    },
    knowledge: (state) => {
        return state.knowledge;
    },
};

const mutations = {
    UPDATE_KNOWLEDGES_TOTAL(state, total) {
        state.total = total;
    },
    UPDATE_KNOWLEDGES(state, knowledges) {
        state.knowledges = knowledges;
    },
    UPDATE_KNOWLEDGE(state, knowledge) {
        if (knowledge) {
            state.knowledges = state.knowledges.filter((p) => p.id !== knowledge.id).concat(knowledge);
            state.knowledge = knowledge;
        } else {
            state.knowledge = undefined;
        }
    },
};

const actions = {
    findKnowledges({ commit, dispatch }, q) {
        let sortBy = '';
        if (q.options.sortBy.length > 0) {
            sortBy = `&sort_by=${q.options.sortBy[0]}.${q.options.sortDesc[0] ? 'asc' : 'desc'}`;
        }
        API.post(`/knowledges/search?limit=${q.options.itemsPerPage}&page=${q.options.page}${sortBy}`, {
            query: q.term,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', undefined);
                commit('UPDATE_KNOWLEDGES', response.data.datas);
                commit('UPDATE_KNOWLEDGES_TOTAL', response.data.total);

                dispatch('findUserLabels', [...new Set(response.data.datas.map((d) => d.createdBy))]);
            }
        });
    },

    findKnowledge({ commit, dispatch }, id) {
        commit('UPDATE_KNOWLEDGE', undefined);
        API.get('/knowledges/' + id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);

                let users = [response.data.datas.createdBy, response.data.datas.updatedBy];
                users = users.concat(response.data.datas.comments.map((c) => c.user));

                dispatch('findUserLabels', [...new Set(users)]);
            }
        });
    },
    createKnowledge({ commit, dispatch }, knowledge) {
        return API.put('/knowledges', knowledge).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.created_success'));
                return Promise.resolve();
            } else {
                return Promise.reject(response.data.message);
            }
        });
    },
    updateKnowledge({ commit, dispatch }, knowledge) {
        API.post('/knowledges/' + knowledge.id, knowledge).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));
            }
        });
    },

    addFilesToKnowledge({ commit, dispatch }, knowledge) {
        return API.post('/knowledges/' + knowledge.id + '/files', knowledge.data).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    deleteFileToKnowledge({ commit, dispatch }, knowledge) {
        return API.delete('/knowledges/' + knowledge.id + '/files/' + knowledge.file).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    addFilesToNewKnowledge({ dispatch }, files) {
        return API.post('/files/', files.data).then((response) => {
            if (response.data.success) {
                dispatch('showInfo', app.$t('knowledges.actions.added_files_success'));
                return Promise.resolve(response.data.datas);
            } else {
                return Promise.reject();
            }
        });
    },
    deleteFileToNewKnowledge({ commit, dispatch }, file) {
        // Remove /api from url
        return API.delete(file.url.substring(4)).then((response) => {
            if (response.data.success) {
                //commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.deleted_files_success'));

                return Promise.resolve(response.data.datas);
            } else {
                return Promise.reject();
            }
        });
    },
    addCommentToKnowledge({ commit, dispatch }, knowledge) {
        return API.put('/knowledges/' + knowledge.id + '/comments', knowledge.comment).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));

                let users = [response.data.datas.createdBy, response.data.datas.updatedBy];
                users = users.concat(response.data.datas.comments.map((c) => c.user));

                dispatch('findUserLabels', [...new Set(users)]);

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    updateCommentToKnowledge({ commit, dispatch }, knowledge) {
        return API.post('/knowledges/' + knowledge.id + '/comments', knowledge.comment).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));

                let users = [response.data.datas.createdBy, response.data.datas.updatedBy];
                users = users.concat(response.data.datas.comments.map((c) => c.user));

                dispatch('findUserLabels', [...new Set(users)]);

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    likeCommentToKnowledge({ commit, dispatch }, knowledge) {
        return API.post(`/knowledges/${knowledge.id}/comments/${knowledge.commentId}/likes`).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));

                let users = [response.data.datas.createdBy, response.data.datas.updatedBy];
                users = users.concat(response.data.datas.comments.map((c) => c.user));

                dispatch('findUserLabels', [...new Set(users)]);

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    dislikeCommentToKnowledge({ commit, dispatch }, knowledge) {
        return API.delete(`/knowledges/${knowledge.id}/comments/${knowledge.commentId}/likes`).then((response) => {
            if (response.data.success) {
                commit('UPDATE_KNOWLEDGE', response.data.datas);
                dispatch('showInfo', app.$t('knowledges.actions.updated_success'));

                let users = [response.data.datas.createdBy, response.data.datas.updatedBy];
                users = users.concat(response.data.datas.comments.map((c) => c.user));

                dispatch('findUserLabels', [...new Set(users)]);

                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
