import { API } from '@/utils/api';

const state = {
    treeview: [],
    treeviewFlatten: [],
    article: undefined,
    loading: false,
    openFolders: [],
    searchResult: [],
    search: undefined,
};

const getters = {
    treeviewDoc: (state) => state.treeview,
    articleDoc: (state) => state.article,
    loadingDoc: (state) => state.loading,
    openFoldersDoc: (state) => state.openFolders,
    searchResultDoc: (state) => state.searchResult,
    treeviewResult: (state) => state.treeviewFlatten.filter((t) => state.searchResult.includes(t.id)),
    searchDoc: (state) => state.search,
};

const mutations = {
    UPDATE_DOC_TREEVIEW(state, treeview) {
        state.openFolders = [];
        state.article = undefined;
        state.treeview = treeview ? treeview : [];
        state.treeviewFlatten = treeview ? documentationFlatten(treeview).filter((d) => d.type === 'page') : [];
        state.search = undefined;
        state.searchResult = [];
    },
    UPDATE_DOC_ARTICLE(state, actives) {
        state.openFolders = actives.slice(0, actives.length - 1);
        state.article = findTreeviewItem(state.treeview, actives[actives.length - 1]);
    },
    UPDATE_SEARCH_DOC(state, result) {
        state.searchResult = result.articles;
        state.search = result.search;
    },
    LOADING_DOC(state, loading) {
        state.loading = loading;
    },
};

const actions = {
    async findTreeviewDoc({ commit, dispatch }, doc) {
        try {
            commit('LOADING_DOC', true);
            const documentation = await API.get(`projects/${doc.project_id}/versions/${doc.version_id}/documentation`);

            commit('LOADING_DOC', false);
            commit('UPDATE_DOC_TREEVIEW', documentation.data.datas);

            if (doc.page) {
                dispatch('showArticleDoc', doc.page);
            }
        } catch (error) {
            commit('LOADING_DOC', false);
            commit('UPDATE_DOC_TREEVIEW', undefined);
        }
    },
    showArticleDoc({ commit }, article) {
        if (article) {
            const elements = article.split('/');
            let activeElements = [];
            for (let i = 0; i < elements.length; i++) {
                if (i === 0) {
                    activeElements[i] = elements[i];
                } else {
                    activeElements[i] = `${activeElements[i - 1]}/${elements[i]}`;
                }
            }

            commit('UPDATE_DOC_ARTICLE', activeElements);
        }
    },
    async searchArticleDoc({ commit, getters }, doc) {
        if (getters.searchDoc !== doc.term) {
            if (doc.term) {
                const documentation = await API.post(
                    `projects/${doc.project_id}/versions/${doc.version_id}/documentation/search`,
                    {
                        query: doc.term,
                    },
                );

                const articles = documentation.data.datas;

                commit('UPDATE_SEARCH_DOC', { articles, search: doc.term });
            } else {
                commit('UPDATE_SEARCH_DOC', { articles: [], search: undefined });
            }
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};

const findTreeviewItem = function (items, id) {
    if (items) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id == id) {
                return items[i];
            }
            let found = findTreeviewItem(items[i].children, id);
            if (found) return found;
        }
    }
};

const documentationFlatten = function (item) {
    let children = [];

    return item
        .map((i) => {
            const m = { ...i }; // use spread operator
            if (m.children && m.children.length) {
                children = [...children, ...m.children];
            }
            delete m.children; // this will not affect the original array object
            return m;
        })
        .concat(children.length ? documentationFlatten(children) : children);
};
