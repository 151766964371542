import app from '@/main';
import { API } from '@/utils/api';

const state = {
    profiles: [],
    profile: undefined,
    users: [],
    user: undefined,
};

const getters = {
    profiles: (state) => {
        return state.profiles;
    },
    editingProfile: (state) => {
        return state.profile;
    },
    users: (state) => {
        return state.users;
    },
    user: (state) => {
        return state.user;
    },
};

const mutations = {
    UPDATE_PROFILES(state, profiles) {
        state.profiles = profiles;
    },
    UPDATE_EDIT_PROFILE(state, profile) {
        state.profile = profile;
    },
    UPDATE_USERS(state, users) {
        state.users = users;
    },
    UPDATE_USER(state, user) {
        state.user = user;
        state.users = state.users.filter((u) => u.name !== user.name).concat(user);
    },
};

const actions = {
    findProfiles({ commit }) {
        API.get('/profiles').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROFILES', response.data.datas);
            }
        });
    },
    findProfile({ commit }, id) {
        API.get('/profiles/' + id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
            }
        });
    },
    createProfile({ dispatch }, profile) {
        API.put('/profiles/', profile).then((response) => {
            if (response.data.success) {
                dispatch('findProfiles');
            }
        });
    },
    updateProfile({ dispatch }, profile) {
        API.post('/profiles/' + profile.id, profile).then((response) => {
            if (response.data.success) {
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    addUserToProfile({ dispatch }, profile) {
        API.put('/profiles/' + profile.id + '/members', profile).then((response) => {
            if (response.data.success) {
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    removeUserToProfile({ dispatch }, profile) {
        API.request({
            method: 'delete',
            url: `/profiles/${profile.id}/members`,
            data: profile,
        }).then((response) => {
            if (response.data.success) {
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    addProjectToProfile({ commit, dispatch }, profile) {
        API.put('/profiles/' + profile.profile_id + '/projects', profile).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    removeProjectToProfile({ commit, dispatch }, profile) {
        API.request({
            method: 'delete',
            url: `/profiles/${profile.profile_id}/projects`,
            data: profile,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    addViewToProfile({ commit, dispatch }, payload) {
        API.put('/profiles/' + payload.profile + '/views', payload.view).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    updateViewToProfile({ commit, dispatch }, payload) {
        API.post('/profiles/' + payload.profile + '/views/' + payload.view.id, payload.view).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    deleteViewToProfile({ commit, dispatch }, payload) {
        API.delete('/profiles/' + payload.profile + '/views/' + payload.id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    addPackToProfile({ commit, dispatch }, profile) {
        API.put('/profiles/' + profile.profile_id + '/packs', profile).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    removePackToProfile({ commit, dispatch }, profile) {
        API.request({
            method: 'delete',
            url: `/profiles/${profile.profile_id}/packs`,
            data: profile,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_EDIT_PROFILE', response.data.datas);
                dispatch('showInfo', app.$t('profiles.actions.updated_success'));
            }
        });
    },
    findUsers({ commit }) {
        return API.get('/users').then((response) => {
            if (response.data.success) {
                commit('UPDATE_USERS', response.data.datas);
                return Promise.resolve(response.data.datas);
            } else {
                return Promise.reject();
            }
        });
    },
    findUser({ commit }, name) {
        API.get('/users/' + name).then((response) => {
            if (response.data.success) {
                commit('UPDATE_USER', response.data.datas);
            }
        });
    },
    updateUser({ commit }, user) {
        API.post('/users/' + user.id, user).then((response) => {
            if (response.data.success) {
                commit('UPDATE_USER', response.data.datas);
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
