import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export const defaultRedirection = '/dashboard';

const routes = [
    {
        path: '/issues/:issue_id',
        name: 'issue-redirect',
        component: () => import('./views/issues/redirect.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('./views/dashboard/index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'calendar',
        path: '/calendar',
        component: () => import('./views/calendar/index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'profile',
        path: '/profile',
        component: () => import('./views/profile/index.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'times',
        path: '/times',
        component: () => import('./views/times/index.vue'),
        meta: {
            requiresAuth: true,
            securities: ['timesAccess'],
        },
    },
    {
        name: 'projects',
        path: '/projects',
        components: {
            default: () => import('./views/projects/index.vue'),
        },
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/projects/:project_id',
        components: {
            default: () => import('./views/projects/project/layout.vue'),
            drawer: () => import('./components/drawers/drawer-project.vue'),
        },
        children: [
            {
                name: 'project',
                path: '',
                redirect: { name: 'project-issues' },
            },
            {
                path: 'issues',
                name: '',
                component: () => import('./views/issues/layout.vue'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'project-issues',
                        component: () => import('./views/projects/project/issues.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'create',
                        name: 'project-issue-create',
                        component: () => import('./views/issues/create.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: ':issue_id',
                        name: 'project-issue',
                        component: () => import('./views/issues/issue.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
            {
                path: 'views/create',
                name: 'project-view-create',
                component: () => import('./views/projects/view/createInProject.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'views/edit/:view_id',
                name: 'project-view-edit',
                component: () => import('./views/projects/view/editInProject.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'roadmap',
                name: 'project-roadmap',
                component: () => import('./views/projects/project/roadmap.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'migration',
                name: 'project-migration',
                component: () => import('./views/projects/project/migration.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'versions',
                name: 'project-versions',
                component: () => import('./views/projects/project/versions.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'versions/:version',
                name: 'project-version',
                component: () => import('./views/projects/project/versions/version.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'backlog',
                name: 'project-backlog',
                component: () => import('./views/projects/project/backlog.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'testing',
                name: 'project-testing',
                component: () => import('./views/projects/project/building.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'analytics',
                name: 'project-analytics',
                component: () => import('./views/projects/project/analytics.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'settings',
                name: 'project-settings',
                component: () => import('./views/projects/project/settings.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'versions/:version/docs',
                component: () => import('./views/projects/project/docs.vue'),
                children: [
                    {
                        path: '',
                        name: 'project-docs',
                        component: () => import('./views/projects/project/docs/changelog.vue'),
                    },
                    {
                        path: '(.*)',
                        name: 'docs-page',
                        component: () => import('./views/projects/project/docs/page.vue'),
                    },
                ],
            },
        ],
    },
    {
        name: 'teams',
        path: '/teams',
        components: {
            default: () => import('./views/teams/index.vue'),
        },
        meta: {
            requiresAuth: true,
            securities: ['sprints'],
        },
    },
    {
        path: '/teams/:team_id',
        components: {
            default: () => import('./views/teams/team/layout.vue'),
            drawer: () => import('./components/drawers/drawer-team.vue'),
        },
        children: [
            {
                name: 'team',
                path: '',
                redirect: { name: 'team-active-sprint' },
            },
            {
                path: 'sprint',
                name: 'team-active-sprint',
                component: () => import('./views/teams/team/active-sprint.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'sprint/:sprint_id',
                name: 'team-sprint',
                component: () => import('./views/teams/team/sprint.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'sprints',
                name: 'team-sprints',
                component: () => import('./views/teams/team/sprints.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'settings',
                component: () => import('./views/teams/team/layout.vue'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        name: 'team-settings',
                        path: '',
                        component: () => import('./views/teams/team/settings.vue'),
                    },
                    {
                        path: 'views/create',
                        name: 'team-view-create',
                        component: () => import('./views/teams/view/createInTeam.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'views/edit/:view_id',
                        name: 'team-view-edit',
                        component: () => import('./views/teams/view/editInTeam.vue'),
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        name: 'packs',
        path: '/packs',
        components: {
            default: () => import('./views/packs/index.vue'),
        },
        meta: {
            requiresAuth: true,
            securities: ['packsAccess', 'packsCreate'],
        },
    },
    {
        name: 'pack',
        path: '/packs/:pack_id',
        components: {
            default: () => import('./views/packs/pack.vue'),
        },
        meta: {
            requiresAuth: true,
            securities: ['packsAccess', 'packsCreate'],
        },
    },
    {
        name: 'settings',
        path: '/settings',
        redirect: { name: 'settings-users' },
        components: {
            default: () => import('./views/settings/layout.vue'),
        },
        meta: {
            requiresAuth: true,
            securities: ['administration'],
        },
        children: [
            {
                name: 'settings-profiles',
                path: 'profiles',
                components: {
                    default: () => import('./views/settings/profiles.vue'),
                },
            },
            {
                name: 'settings-profile',
                path: 'profiles/:profile_id',
                components: {
                    default: () => import('./views/settings/profile.vue'),
                },
            },
            {
                name: 'settings-users',
                path: 'users',
                components: {
                    default: () => import('./views/settings/users.vue'),
                },
            },
            {
                name: 'settings-trackers',
                path: 'trackers',
                components: {
                    default: () => import('./views/settings/trackers.vue'),
                },
            },
            {
                name: 'settings-status',
                path: 'status',
                components: {
                    default: () => import('./views/settings/status.vue'),
                },
            },
            {
                name: 'settings-priorities',
                path: 'priorities',
                components: {
                    default: () => import('./views/settings/priorities.vue'),
                },
            },
            {
                name: 'settings-severities',
                path: 'severities',
                components: {
                    default: () => import('./views/settings/severities.vue'),
                },
            },
        ],
    },
    {
        name: 'knowledges',
        path: '/knowledges',
        redirect: { name: 'knowledges-list' },
        components: {
            default: () => import('./views/knowledges/layout.vue'),
        },
        meta: {
            requiresAuth: true,
            securities: ['knowledgesAccess'],
        },
        children: [
            {
                name: 'knowledges-list',
                path: '',
                components: {
                    default: () => import('./views/knowledges/index.vue'),
                },
            },
            {
                name: 'knowledge-create',
                path: 'create',
                components: {
                    default: () => import('./views/knowledges/create.vue'),
                },
            },
            {
                path: ':knowledge_id',
                name: 'knowledge',
                component: () => import('./views/knowledges/knowledge.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        name: 'analytics',
        path: '/analytics',
        redirect: { name: 'analytics-times' },
        components: {
            default: () => import('./views/analytics/layout.vue'),
        },
        meta: {
            requiresAuth: true,
            securities: ['analyticsAccess'],
        },
        children: [
            {
                name: 'analytics-times',
                path: '',
                components: {
                    default: () => import('./views/analytics/timesByUser.vue'),
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/login/index.vue'),
    },
    {
        path: '/login/success',
        name: 'login-success',
        component: () => import('./views/login/success.vue'),
    },
    {
        path: '/not-found',
        name: 'notFound',
        component: () => import('./views/errors/notFound.vue'),
    },
    {
        path: '*',
        redirect: defaultRedirection,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    const profile = router.app.$store.getters.profile;

    const profileRedirection = profile ? '/' + profile.defaultPage : defaultRedirection;

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!profile) {
            next({
                name: 'login',
                params: { nextUrl: to.name === 'login' ? '' : to.fullPath },
            });
        } else {
            if (to.name === 'login') {
                next({ path: profileRedirection });
            } else {
                const securities = to.matched.map((r) => r.meta.securities).reduce((a, b) => (b ? a.concat(b) : a), []);
                if (securities.length > 0) {
                    if (securities.every((s) => profile.security[s] === false)) {
                        next({ path: '/not-found' });
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            }
        }
    } else {
        if (profile && to.name === 'login') {
            next({ path: profileRedirection });
        } else {
            next();
        }
    }
});

export default router;
