import app from '@/main';
import { API } from '@/utils/api';

const state = {
    projects: [],
    project: undefined,
};

const getters = {
    projects: (state) => {
        return state.projects;
    },
    project: (state) => {
        return state.project;
    },
    roles: (state) => {
        return ['productOwner', 'productManager', 'developer', 'reporter', 'viewer', 'support', 'reader'];
    },
    versionStatus: (state) => {
        return ['major', 'minor', 'fix'];
    },
    projectVersions: (state) => {
        return state.project.versions.map((v) => {
            return {
                text: v.name,
                value: v.name,
            };
        });
    },
};

const mutations = {
    UPDATE_PROJECTS(state, projects) {
        state.projects = projects;
    },
    UPDATE_PROJECT(state, project) {
        if (project) {
            state.projects = state.projects.filter((p) => p.id !== project.id).concat(project);
            state.project = project;
        } else {
            state.project = undefined;
        }
    },
};

const actions = {
    findProjects({ commit }) {
        return API.get('/projects').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', undefined);
                commit('UPDATE_PROJECTS', response.data.datas);
                return Promise.resolve(response.data.datas);
            } else {
                return Promise.reject();
            }
        });
    },
    findAllProjects({ commit }) {
        API.get('/projects').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECTS', response.data.datas);
            }
        });
    },
    searchProjects({ commit }, search) {
        return API.post('/projects/search', search).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECTS', response.data.datas);
                return Promise.resolve(response.data.datas);
            } else {
                return Promise.reject();
            }
        });
    },
    findProject({ commit, dispatch }, id) {
        commit('UPDATE_PROJECT', undefined);
        return API.get('/projects/' + id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch(
                    'findUserLabels',
                    response.data.datas.members.map((m) => m.user),
                );
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
    createProject({ commit, dispatch }, project) {
        API.put('/projects', project).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.created_success'));
            }
        });
    },
    updateProject({ commit, dispatch }, project) {
        API.post('/projects/' + project.id, project).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    addVersion({ commit, dispatch }, version) {
        API.put('/projects/' + version.id + '/versions', version).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    updateVersion({ commit, dispatch }, version) {
        API.post('/projects/' + version.id + '/versions/' + version.versionId, version).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    deleteVersion({ commit, dispatch }, version) {
        API.request({
            method: 'delete',
            url: `/projects/${version.id}/versions/${version.versionId}`,
            data: version,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    updateModule({ commit, dispatch }, module) {
        API.post('/projects/' + module.id + '/modules', module).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    deleteModule({ commit, dispatch }, module) {
        API.request({
            method: 'delete',
            url: `/projects/${module.id}/modules`,
            data: module,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    updateCategory({ commit, dispatch }, category) {
        API.post('/projects/' + category.id + '/categories', category).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    deleteCategory({ commit, dispatch }, category) {
        API.request({
            method: 'delete',
            url: `/projects/${category.id}/categories`,
            data: category,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    updateProjectMember({ commit, dispatch }, member) {
        API.post('/projects/' + member.id + '/members', member).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    deleteProjectMember({ commit, dispatch }, member) {
        API.request({
            method: 'delete',
            url: `/projects/${member.id}/members`,
            data: member,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    addProjectView({ commit, dispatch }, payload) {
        API.put('/projects/' + payload.project + '/views', payload.view).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    updateProjectView({ commit, dispatch }, payload) {
        API.post('/projects/' + payload.project + '/views/' + payload.view.id, payload.view).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    deleteProjectView({ commit, dispatch }, payload) {
        API.delete('/projects/' + payload.project + '/views/' + payload.view.id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    addProjectToFavorite({ commit, dispatch }, id) {
        API.post('/projects/' + id + '/favorites').then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    removeProjectToFavorite({ commit, dispatch }, id) {
        API.request({
            method: 'delete',
            url: `/projects/${id}/favorites`,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
    updateIntegration({ commit, dispatch }, integration) {
        API.post('/projects/' + integration.id + '/integrations/' + integration.tool, integration).then((response) => {
            if (response.data.success) {
                commit('UPDATE_PROJECT', response.data.datas);
                dispatch('showInfo', app.$t('projects.actions.updated_success'));
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
