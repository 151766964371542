import app from '@/main';
import { API } from '@/utils/api';

const state = {
    teams: [],
    team: undefined,
};

const getters = {
    teams: (state) => {
        return state.teams;
    },
    team: (state) => {
        return state.team;
    },
    sprintStatus: (state) => {
        return ['active', 'close'];
    },
};

const mutations = {
    UPDATE_TEAMS(state, teams) {
        state.teams = teams;
    },
    UPDATE_TEAM(state, team) {
        if (team) {
            state.teams = state.teams.filter((p) => p.id !== team.id).concat(team);
            state.team = team;
        } else {
            state.team = undefined;
        }
    },
};

const actions = {
    findTeams({ commit }) {
        API.get('/teams').then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', undefined);
                commit('UPDATE_TEAMS', response.data.datas);
            }
        });
    },

    findTeam({ commit, dispatch }, id) {
        commit('UPDATE_TEAM', undefined);
        API.get('/teams/' + id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch(
                    'findUserLabels',
                    response.data.datas.members.map((m) => m.user),
                );
            }
        });
    },
    createTeam({ commit, dispatch }, team) {
        API.put('/teams', team).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.created_success'));
            }
        });
    },
    updateTeam({ commit, dispatch }, team) {
        API.post('/teams/' + team.id, team).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    updateTeamMember({ commit, dispatch }, member) {
        API.post('/teams/' + member.id + '/members', member).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch(
                    'findUserLabels',
                    response.data.datas.members.map((m) => m.user),
                );
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    deleteTeamMember({ commit, dispatch }, member) {
        API.request({
            method: 'delete',
            url: `/teams/${member.id}/members`,
            data: member,
        }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    addTeamKanban({ commit, dispatch }, payload) {
        API.put('/teams/' + payload.team + '/kanbans', payload).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    updateTeamKanban({ commit, dispatch }, payload) {
        API.post('/teams/' + payload.team + '/kanbans/' + payload.id, payload).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    deleteTeamKanban({ commit, dispatch }, payload) {
        API.delete('/teams/' + payload.team + '/kanbans/' + payload.id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    addTeamView({ commit, dispatch }, payload) {
        API.put('/teams/' + payload.team + '/views', payload.view).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    updateTeamView({ commit, dispatch }, payload) {
        API.post('/teams/' + payload.team + '/views/' + payload.view.id, payload.view).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    deleteTeamView({ commit, dispatch }, payload) {
        API.delete('/teams/' + payload.team + '/views/' + payload.view.id).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    addTeamSprint({ commit, dispatch }, payload) {
        API.put('/teams/' + payload.team + '/sprints', payload).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
    updateTeamSprint({ commit, dispatch }, payload) {
        API.post('/teams/' + payload.team + '/sprints/' + payload.id, payload).then((response) => {
            if (response.data.success) {
                commit('UPDATE_TEAM', response.data.datas);
                dispatch('showInfo', app.$t('teams.actions.updated_success'));
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
