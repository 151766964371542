import { API } from '@/utils/api';
import OrderBy from 'lodash/orderBy';

const state = {
    usersLabels: [],
    projectUsers: [],
};

const getters = {
    usersLabels: (state) => {
        return state.usersLabels;
    },

    projectUsers: (state) => {
        return OrderBy(
            state.projectUsers.map((u) => {
                return {
                    value: u,
                    text: state.usersLabels.filter((v) => v.name === u)[0].fullname,
                };
            }),
            ['text'],
            ['asc'],
        );
    },
};

const mutations = {
    UPDATE_USER_LABEL(state, user) {
        state.usersLabels = state.usersLabels.filter((u) => u.name !== user.name).concat(user);
    },
    UPDATE_USER_LABELS(state, users) {
        const existUsers = users.map((u) => u.name);
        state.usersLabels = [...state.usersLabels.filter((u) => existUsers.includes(u.name)), ...users];
    },
    UPDATE_PROJECT(state, project) {
        if (project) {
            state.projectUsers = project.members ? project.members.map((u) => u.user) : [];
        }
    },
};

const actions = {
    async findUserLabels({ commit }, emails) {
        return API.post('/users/labels', { emails: emails }).then((response) => {
            if (response.data.success) {
                commit('UPDATE_USER_LABELS', response.data.datas);
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
