import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, min, max, numeric } from 'vee-validate/dist/rules';
import Vue from 'vue';

localize('fr', {
    code: 'fr',
    messages: {
        alpha: 'Le champ {_field_} ne peut contenir que des lettres',
        alpha_num: 'Le champ {_field_} ne peut contenir que des caractères alpha-numériques',
        alpha_dash: 'Le champ {_field_} ne peut contenir que des caractères alpha-numériques, tirets ou soulignés',
        alpha_spaces: 'Le champ {_field_} ne peut contenir que des lettres ou des espaces',
        between: 'Le champ {_field_} doit être compris entre {min} et {max}',
        confirmed: 'Le champ {_field_} ne correspond pas',
        digits: 'Le champ {_field_} doit être un nombre entier de {length} chiffres',
        dimensions: 'Le champ {_field_} doit avoir une taille de {width} pixels par {height} pixels',
        email: 'Le champ {_field_} doit être une adresse e-mail valide',
        excluded: 'Le champ {_field_} doit être une valeur valide',
        ext: 'Le champ {_field_} doit être un fichier valide',
        image: 'Le champ {_field_} doit être une image',
        integer: 'Le champ {_field_} doit être un entier',
        length: 'Le champ {_field_} doit contenir {length} caractères',
        max_value: 'Le champ {_field_} doit avoir une valeur de {max} ou moins',
        max: 'Le champ {_field_} ne peut pas contenir plus de {length} caractères',
        mimes: 'Le champ {_field_} doit avoir un type MIME valide',
        min_value: 'Le champ {_field_} doit avoir une valeur de {min} ou plus',
        min: 'Le champ {_field_} doit contenir au minimum {length} caractères',
        numeric: 'Le champ {_field_} ne peut contenir que des chiffres',
        oneOf: 'Le champ {_field_} doit être une valeur valide',
        regex: 'Le champ {_field_} est invalide',
        required: 'Le champ {_field_} est obligatoire',
        required_if: 'Le champ {_field_} est obligatoire lorsque {target} possède cette valeur',
        size: 'Le champ {_field_} doit avoir un poids inférieur à {size}KB',
        decimal: 'Le champ {_field_} ne peut contenir que des chiffres',
    },
});

// Install rules
extend('required', required);
extend('email', email);
extend('min', min);
extend('max', max);
extend('numeric', numeric);
extend('decimal', (value) => !isNaN(parseFloat(value)));

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
