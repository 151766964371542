import app from '@/main';
import { API } from '@/utils/api';
import i18n from '@/plugins/i18n';

const SESSIONSTORAGE_TRACKERS = 'product_trackers';
const SESSIONSTORAGE_STATUS = 'product_status';
const SESSIONSTORAGE_PRIORITIES = 'product_priorities';
const SESSIONSTORAGE_SEVERITIES = 'product_severities';

const state = {
    trackers: [],
    status: [],
    severities: [],
    priorities: [],
};

const getters = {
    trackers: (state) => {
        return state.trackers;
    },
    status: (state) => {
        return state.status;
    },
    severities: (state) => {
        return state.severities;
    },
    priorities: (state) => {
        return state.priorities;
    },
};

const mutations = {
    LOAD_TRACKERS(state) {
        state.trackers = JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_TRACKERS));
    },
    UPDATE_TRACKERS(state, trackers) {
        state.trackers = trackers.map((t) => {
            t.label = i18n.t(`lists.trackers.${t.name}`);
            return t;
        });
        window.sessionStorage.setItem(SESSIONSTORAGE_TRACKERS, JSON.stringify(state.trackers));
    },
    LOAD_STATUS(state) {
        state.status = JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_STATUS));
    },
    UPDATE_STATUS(state, status) {
        state.status = status.map((s) => {
            s.label = i18n.t(`lists.status.${s.name}`);
            return s;
        });

        window.sessionStorage.setItem(SESSIONSTORAGE_STATUS, JSON.stringify(state.status));
    },

    LOAD_PRIORITIES(state) {
        state.priorities = JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_PRIORITIES));
    },
    UPDATE_PRIORITIES(state, priorities) {
        state.priorities = priorities.map((p) => {
            p.label = i18n.t(`lists.priorities.${p.value}`);
            return p;
        });

        window.sessionStorage.setItem(SESSIONSTORAGE_PRIORITIES, JSON.stringify(state.priorities));
    },
    LOAD_SEVERITIES(state) {
        state.severities = JSON.parse(window.sessionStorage.getItem(SESSIONSTORAGE_SEVERITIES));
    },
    UPDATE_SEVERITIES(state, severities) {
        state.severities = severities.map((p) => {
            p.label = i18n.t(`lists.severities.${p.value}`);
            return p;
        });

        window.sessionStorage.setItem(SESSIONSTORAGE_SEVERITIES, JSON.stringify(state.severities));
    },
};

const actions = {
    loadAppConfig({ dispatch }) {
        dispatch('findTrackers', true);
        dispatch('findStatus', true);
        dispatch('findPriorities', true);
        dispatch('findSeverities', true);
        dispatch('findProjects');
        dispatch('findTeams');
    },
    findTrackers({ commit }, force) {
        if (force) {
            API.get('/config/trackers').then((response) => {
                if (response.data.success) {
                    commit('UPDATE_TRACKERS', response.data.datas);
                }
            });
        } else {
            commit('LOAD_TRACKERS');
        }
    },
    createTracker({ dispatch }, tracker) {
        API.put('/config/trackers', tracker).then((response) => {
            if (response.data.success) {
                dispatch('findTrackers', true);
                dispatch('showSuccess', app.$t('trackers.actions.created_success'));
            }
        });
    },
    updateTracker({ dispatch }, tracker) {
        API.post(`/config/trackers/${tracker.id}`, tracker).then((response) => {
            if (response.data.success) {
                dispatch('findTrackers', true);
                dispatch('showInfo', app.$t('trackers.actions.updated_success'));
            }
        });
    },
    deleteTracker({ dispatch }, tracker) {
        let url = `/config/trackers/${tracker.id}`;
        if (tracker.replace) {
            url = url + '/' + tracker.replace;
        }
        API.delete(url).then((response) => {
            if (response.data.success) {
                dispatch('findTrackers', true);
                dispatch('showInfo', app.$t('trackers.actions.updated_success'));
            }
        });
    },
    findStatus({ commit }, force) {
        if (force) {
            API.get('/config/status').then((response) => {
                if (response.data.success) {
                    commit('UPDATE_STATUS', response.data.datas);
                }
            });
        } else {
            commit('LOAD_STATUS');
        }
    },
    createStatus({ dispatch }, status) {
        API.put('/config/status', status).then((response) => {
            if (response.data.success) {
                dispatch('findStatus', true);
                dispatch('showSuccess', app.$t('status.actions.created_success'));
            }
        });
    },
    updateStatus({ dispatch }, status) {
        API.post(`/config/status/${status.id}`, status).then((response) => {
            if (response.data.success) {
                dispatch('findStatus', true);
                dispatch('showInfo', app.$t('status.actions.updated_success'));
            }
        });
    },
    deleteStatus({ dispatch }, status) {
        let url = `/config/status/${status.id}`;
        if (status.replace) {
            url = url + '/' + status.replace;
        }
        API.delete(url).then((response) => {
            if (response.data.success) {
                dispatch('findStatus', true);
                dispatch('showInfo', app.$t('status.actions.updated_success'));
            }
        });
    },

    findPriorities({ commit }, force) {
        if (force) {
            API.get('/config/priorities').then((response) => {
                if (response.data.success) {
                    commit('UPDATE_PRIORITIES', response.data.datas);
                }
            });
        } else {
            commit('LOAD_PRIORITIES');
        }
    },
    createPriority({ dispatch }, priority) {
        API.put('/config/priorities', priority).then((response) => {
            if (response.data.success) {
                dispatch('findPriorities', true);
                dispatch('showSuccess', app.$t('priorities.actions.created_success'));
            }
        });
    },
    updatePriority({ dispatch }, priority) {
        API.post(`/config/priorities/${priority.id}`, priority).then((response) => {
            if (response.data.success) {
                dispatch('findPriorities', true);
                dispatch('showInfo', app.$t('priorities.actions.updated_success'));
            }
        });
    },
    deletePriority({ dispatch }, priority) {
        let url = `/config/priorities/${priority.id}`;
        if (priority.replace) {
            url = url + '/' + priority.replace;
        }
        API.delete(url).then((response) => {
            if (response.data.success) {
                dispatch('findPriorities', true);
                dispatch('showInfo', app.$t('priorities.actions.updated_success'));
            }
        });
    },

    findSeverities({ commit }, force) {
        if (force) {
            API.get('/config/severities').then((response) => {
                if (response.data.success) {
                    commit('UPDATE_SEVERITIES', response.data.datas);
                }
            });
        } else {
            commit('LOAD_SEVERITIES');
        }
    },
    createSeverity({ dispatch }, severity) {
        API.put('/config/severities', severity).then((response) => {
            if (response.data.success) {
                dispatch('findSeverities', true);
                dispatch('showSuccess', app.$t('severities.actions.created_success'));
            }
        });
    },
    updateSeverity({ dispatch }, severity) {
        API.post(`/config/severities/${severity.id}`, severity).then((response) => {
            if (response.data.success) {
                dispatch('findSeverities', true);
                dispatch('showInfo', app.$t('severities.actions.updated_success'));
            }
        });
    },
    deleteSeverity({ dispatch }, severity) {
        let url = `/config/severities/${severity.id}`;
        if (severity.replace) {
            url = url + '/' + severity.replace;
        }
        API.delete(url).then((response) => {
            if (response.data.success) {
                dispatch('findSeverities', true);
                dispatch('showInfo', app.$t('severities.actions.updated_success'));
            }
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
